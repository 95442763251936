import logo from './logo.svg';
import './App.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Card } from 'primereact/card';
import { css } from '@emotion/react';
import { initializeApp } from "firebase/app";
import Ramify from './Ramify';
import KeyManager from './KeyManager';
import functionsService from './functionsService';

const firebaseConfig = {
  apiKey: "AIzaSyCTYGW9t3ix6HV_lezZCulKYLY6UJH9e-g",
  authDomain: "gt-ramify.firebaseapp.com",
  projectId: "gt-ramify",
  storageBucket: "gt-ramify.appspot.com",
  messagingSenderId: "133440073787",
  appId: "1:133440073787:web:7ec039fc393eade78e2c1e",
  measurementId: "G-TPWSVYWNS2"
};

const app = initializeApp(firebaseConfig);
functionsService.init(app);

function App() {
  return (
    <PrimeReactProvider>
      <div className="App">
        <div className="max-w-lg mx-auto my-4">
          <KeyManager className="mb-50"></KeyManager>
        </div>
        <Ramify />
      </div>
    </PrimeReactProvider>
  );
}

export default App;
