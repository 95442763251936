import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

class FunctionsService {
    constructor() {
        this.isInitialized = false;
        this.functions = null;
    }

    init(app) {
        if (this.isInitialized) {
            return; // If already initialized, do nothing
        }

        this.functions = getFunctions(app);
        connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);

        this.isInitialized = true; // Mark as initialized
    }

    async call_fn(functionName, args) {
        const fn = httpsCallable(this.functions, functionName);
        return fn(args);
    }

    get_fn(functionName) {
        const fn = httpsCallable(this.functions, functionName);
        return fn;
    }
}

const functionsService = new FunctionsService();
export default functionsService;
