import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import NodeTree from './NodeTree';
import { getTreeIncrementally } from './treeService';

function Ramify() {
    const [inputValue, setInputValue] = useState('');
    const [rootNode, setRootNode] = useState();

    const handleSubmit = () => {
        const treeObservable = getTreeIncrementally(inputValue);

        treeObservable.subscribe({
            next: (tree) => {
                console.log({ tree });
                setRootNode({ ...tree });
            },
            error: (err) => console.error(err),
            complete: () => console.log('Tree processing complete.')
        });
    };


    return (
        <div className="">
            <div className="max-w-lg mx-auto my-4">
                <label className="block mb-2" htmlFor="input">Prompt:</label>
                <InputText className="w-full mb-4" id="input" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                <Button className="w-full" label="Submit" onClick={handleSubmit} disabled={!inputValue} />
            </div>
            <div className="my-10">
                {rootNode ? <NodeTree data={rootNode}></NodeTree> : ''}
            </div>
        </div>
    );
}

export default Ramify;
