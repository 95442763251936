class KeyManagerService {
    getApiKey() {
        return localStorage.getItem('apiKey');
    }

    setApiKey(key) {
        localStorage.setItem('apiKey', key);
    }
}

const keyManagerService = new KeyManagerService();
export default keyManagerService;
