import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import keyManagerService from './keyManagerService';

function KeyManager() {
    const [apiKey, setApiKey] = useState('');
    const [status, setStatus] = useState('');
    useEffect(() => {

        const storedKey = keyManagerService.getApiKey();
        if (storedKey) {
            setApiKey(storedKey);
        }
    }, []);

    const handleSubmit = () => {
        keyManagerService.setApiKey(apiKey);
        setStatus('API Key saved!');
    };

    return (
        <Card>
            <div className="">
                <div className="mb-5">
                    <label className="block mb-2" htmlFor="apiKeyInput">Enter API Key:</label>
                    <InputText className="w-full" id="apiKeyInput" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
                </div>
                <Button className="w-full" label="Submit" onClick={handleSubmit} />
                {status && <p className="mt-3">{status}</p>}
            </div>
        </Card>
    );
}

export default KeyManager;