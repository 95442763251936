import React from 'react';
import { OrganizationChart } from 'primereact/organizationchart';

const NodeTree = ({ data }) => {
    const convertToOrgChartFormat = (node) => {
        if (!data.title) {
            return { expanded: true };
        }
        const lateralProps = {
            content: node.content,
            subTreeDescription: node.subTreeDescription,

        }
        const newNode = {
            label: node.title,
            expanded: true,
            children: node.children ? node.children.map(convertToOrgChartFormat) : [],
            ...lateralProps
        };
        return newNode;
    };

    let orgChartData = convertToOrgChartFormat(data);
    console.log({ orgChartData });

    return (
        <div>
            {Object.keys(orgChartData) ? <OrganizationChart value={[orgChartData]} nodeTemplate={nodeTemplate} style={{ width: '100%' }} /> : ''}
        </div>
    );
};


const nodeTemplate = (node) => {
    if (node.content.kind === 'TEXT') {
        return (
            <div className="w-80">
                <h3>{node.label}</h3>
                <p>{node.content?.value}</p>
            </div>
        );
    } else if (node.content.kind === 'IMAGE') {
        return (
            <div>
                <h3>{node.label}</h3>
                <h4>{node.content.kind}</h4>

                {node?.content?.value ? <img src={node.content.value} alt={node.content.synthesisDescription} style={{ width: '400px', height: '400px' }} /> : <p>{node.content?.synthesisDescription}</p>}
            </div>
        );
    } else if (node.content.kind === 'SVG') {
        return (
            <div>
                <h3>{node.label}</h3>
                <h4>{node.content.kind}</h4>

                {node?.content?.value ? <div dangerouslySetInnerHTML={{ __html: node.content.value }}></div> : <p>{node.content?.synthesisDescription}</p>}
            </div >
        );
    }
    return null;
};

export default NodeTree;
