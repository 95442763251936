import { Observable } from 'rxjs';


import keyManagerService from './keyManagerService';
import functionsService from './functionsService';

export async function getTree(prompt) {
    const openAIApiKey = keyManagerService.getApiKey();

    const getResultNodes = functionsService.get_fn("get_result_nodes");
    const getImage = functionsService.get_fn("get_image");
    const getSVG = functionsService.get_fn("get_svg");

    const response = await getResultNodes({ openai_api_key: openAIApiKey, prompt: prompt });
    const resultJson = response?.data?.result || "{}";
    const tree = JSON.parse(resultJson);

    const imageNodes = filterTree(tree, node => node.content.kind === "IMAGE");
    const svgNodes = filterTree(tree, node => node.content.kind === "SVG");

    const imagePromises = imageNodes.map(node => getImage({ openai_api_key: openAIApiKey, prompt: node.content.synthesisDescription }));
    const svgPromises = svgNodes.map(node => getSVG({ openai_api_key: openAIApiKey, prompt: node.content.synthesisDescription }));

    const allResults = await Promise.all([...imagePromises, ...svgPromises]);
    const imageResults = allResults.filter(r => r?.data?.url);
    const svgResults = allResults.filter(r => !r?.data?.url);

    imageNodes.forEach((node, idx) => {
        node.content.value = imageResults[idx]?.data?.url;
    });

    svgNodes.forEach((node, idx) => {
        node.content.value = svgResults[idx]?.data;
    });

    return tree;
}

export function getTreeIncrementally(prompt) {
    const openAIApiKey = keyManagerService.getApiKey();
    const getResultNodes = functionsService.get_fn("get_result_nodes");
    const getImage = functionsService.get_fn("get_image");
    const getSVG = functionsService.get_fn("get_svg");

    return new Observable(async observer => {
        try {
            const response = await getResultNodes({ openai_api_key: openAIApiKey, prompt: prompt });
            const resultJson = response?.data?.result || "{}";
            const tree = JSON.parse(resultJson);

            observer.next(tree); // Emit the tree structure after it's first retrieved

            const imageNodes = filterTree(tree, node => node.content.kind === "IMAGE");
            const svgNodes = filterTree(tree, node => node.content.kind === "SVG");


            imageNodes.forEach(async (node, idx) => {
                const imageResult = await getImage({ openai_api_key: openAIApiKey, prompt: node.content.synthesisDescription });
                node.content.value = imageResult?.data?.url;
                observer.next(tree);
            });

            svgNodes.forEach(async (node, idx) => {
                const svgResult = await getSVG({ openai_api_key: openAIApiKey, prompt: node.content.synthesisDescription });
                node.content.value = svgResult?.data;
                observer.next(tree);
            });

            // const imagePromises = imageNodes.map(node => getImage({ openai_api_key: openAIApiKey, prompt: node.content.synthesisDescription }));
            // const svgPromises = svgNodes.map(node => getSVG({ openai_api_key: openAIApiKey, prompt: node.content.synthesisDescription }));

            // const allResults = await Promise.all([...imagePromises, ...svgPromises]);
            // const imageResults = allResults.filter(r => r?.data?.url);
            // const svgResults = allResults.filter(r => !r?.data?.url);

            // imageNodes.forEach((node, idx) => {
            //     node.content.value = imageResults[idx]?.data?.url;
            // });

            // svgNodes.forEach((node, idx) => {
            //     node.content.value = svgResults[idx]?.data;
            // });

            // observer.next(tree); // Emit the updated tree with images and SVGs
            // observer.complete();
        } catch (error) {
            observer.error(error);
        }
    });
}

function filterTree(tree, predicate) {
    let result = [];

    function traverse(node) {
        if (predicate(node)) {
            result.push(node);
        }

        if (node.children && node.children.length > 0) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    }

    traverse(tree);

    return result;
}
